import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spaces-to-camel-case',
    standalone: false
})
export class SpacesToCamelCasePipe implements PipeTransform {
  transform(text: string): string {
    const uppercased = text
      .replace(/\s\s+/g, ' ')
      .split(' ')
      .map((word) => {
        const onlyLetters = word.replace(/\W/g, '');
        return onlyLetters[0].toUpperCase() + onlyLetters.slice(1);
      })
      .join('');
    return uppercased[0].toLowerCase() + uppercased.slice(1);
  }
}
