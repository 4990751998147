import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GrantFieldMapping, GrantFieldMappingForm } from '@core/models';
@Component({
    selector: 'app-field-mapping',
    templateUrl: './field-mapping.component.html',
    styleUrls: ['./field-mapping.component.scss'],
    standalone: false
})
export class FieldMappingComponent implements OnInit {
  @Input() columnId: number;
  @Input() fieldNames: string[];
  @Input() missingAllowed: boolean;
  @Input() selectForm: UntypedFormGroup;
  @Input() selectedValues: GrantFieldMapping = {};
  @Input() systemColumnName: string;
  @Input() validateDate: boolean;
  @Input() validateCode: boolean;
  @Input() validCodes: string[];
  @Input() mappedColumns: string[];

  @Output() changeSelection: EventEmitter<any> = new EventEmitter();

  public fieldName: string;
  public fieldValue: object;
  public fieldNameSelect: UntypedFormControl;

  constructor() {}

  ngOnInit(): void {
    this.fieldNameSelect = new UntypedFormControl(null, Validators.required);
    this.fieldName = `fieldName${this.columnId}`;
    if (this.fieldNames.includes(this.systemColumnName)) {
      // auto-mapped columns
      this.fieldValue = {
        name: this.systemColumnName,
        columnId: this.columnId,
        missingAllowed: this.missingAllowed,
        validateDate: this.validateDate,
        validateCode: this.validateCode,
        validCodes: this.validCodes,
        systemColumnName: this.systemColumnName,
      };
      this.fieldNames = [this.systemColumnName];
      this.selectForm.addControl(this.fieldName, this.fieldNameSelect);
      this.selectForm.patchValue(
        { [this.fieldName]: this.fieldValue },
        { onlySelf: false, emitEvent: true },
      );
    } else {
      // non-automapped columns
      this.fieldNames = ['<Not included in file>'].concat(
        this.fieldNames.filter((val) => !this.mappedColumns.includes(val)),
      );
      this.selectForm.addControl(this.fieldName, this.fieldNameSelect);
    }
  }

  public addSelectedValue(event: GrantFieldMappingForm): void {
    this.changeSelection.emit(event);
  }

  public checkSelected(column: string, id: number): boolean {
    return ['<Not included in file>']
      .concat(Object.values(this.selectedValues))
      .filter((val) =>
        this.missingAllowed
          ? !val.startsWith('<Not') && val !== this.selectedValues?.[id]
          : val !== this.selectedValues?.[id],
      )
      .includes(column);
  }
}
