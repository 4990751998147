import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-review-status-box',
    templateUrl: './review-status-box.component.html',
    styleUrls: ['./review-status-box.component.scss'],
    standalone: false
})
export class ReviewStatusBoxComponent implements OnInit {
  @Input() dateSubmitted: string;
  @Input() lastReviewed: string;
  @Input() reviewStatus: string;
  @Input() submissionDue: string;
  @Input() systemForced: boolean;

  constructor() {}

  ngOnInit(): void {}
}
