import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UserGrantItem, UserGrantSelection } from '@core/models';
import { Subject } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-user-grant-list',
    templateUrl: './user-grant-list.component.html',
    styleUrls: ['./user-grant-list.component.scss'],
    standalone: false
})
export class UserGrantListComponent implements OnInit, OnDestroy {
  @Input() grants: UserGrantItem[];
  @Output() grantSelected: EventEmitter<UserGrantSelection> =
    new EventEmitter();

  public pageSize = 10;

  private destroyed$ = new Subject<boolean>();
  private pageIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public grantOnPage(index: number): boolean {
    const previousPage = this.pageIndex;
    const page = this.pageIndex + 1;
    const idToCheck = index + 1;

    return (
      idToCheck <= page * this.pageSize &&
      idToCheck > previousPage * this.pageSize
    );
  }

  public handlePageEvent(e: PageEvent) {
    this.pageIndex = e.pageIndex;
  }

  public setGrantSelection(grantId: number, subrecipientId: number): void {
    this.grantSelected.emit({ grantId, subrecipientId });
  }
}
