import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  FparDataColumn,
  GrantFieldMapping,
  GrantFieldMappingForm,
  GrantFileUploadData,
} from '@core/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-column-mapping',
    templateUrl: './column-mapping.component.html',
    styleUrls: ['./column-mapping.component.scss'],
    standalone: false
})
export class ColumnMappingComponent
  implements AfterViewChecked, OnInit, OnDestroy
{
  @Input() columnList: FparDataColumn[];
  @Input() fileData: GrantFileUploadData;
  @Input() matchedColNames: string[];
  @Input() tableName: string;
  @Input() validationStarted: Observable<boolean>;

  @Output() formChanges: EventEmitter<UntypedFormGroup> = new EventEmitter();

  public displayedColumns: string[] = ['dataElement', 'dataColumn'];
  public form: UntypedFormGroup;
  public panelOpen = true;
  public selectedValuesMap: GrantFieldMapping = {};

  private destroyed$ = new Subject<boolean>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});

    this.form.valueChanges.pipe().subscribe((data) => {
      this.formChanges.emit(this.form);
    });
    this.validationStarted
      .pipe(takeUntil(this.destroyed$))
      .subscribe((validated) => {
        if (validated) {
          this.panelOpen = false;
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public addOption(event: GrantFieldMappingForm): void {
    this.selectedValuesMap = {
      ...this.selectedValuesMap,
      [event.columnId]: event.name,
    };
  }
}
