import { Component, Input, OnInit } from '@angular/core';
import { NotificationTypes } from '@app/shared/enums';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    standalone: false
})
export class BannerComponent implements OnInit {
  @Input() type: NotificationTypes;

  constructor() {}

  public get icon(): string {
    switch (this.type) {
      case NotificationTypes.DANGER:
        return 'error';
      case NotificationTypes.INFO:
        return 'info';
      case NotificationTypes.SUCCESS:
        return 'check_circle';
      case NotificationTypes.WARN:
        return 'fmd_bad';
    }
  }

  ngOnInit(): void {}
}
