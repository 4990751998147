import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NotificationTypes } from '@app/shared/enums';
import {
  FparTableActions,
  FparTableSelectors,
  NotificationActions,
} from '@app/store';
import { FparComment, FparTableCommentPayload } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-fpar-table-comments',
    templateUrl: './fpar-table-comments.component.html',
    styleUrls: ['./fpar-table-comments.component.scss'],
    standalone: false
})
export class FparTableCommentsComponent implements OnDestroy {
  @Input() comments: FparComment[] = [];
  @Input() grant: { selectedGrantId: number; tableId: number };
  @Input() readOnly = false;
  @Input() tableFormIsDirty: boolean;

  @Output() editingComments = new EventEmitter<boolean>();

  public saving$: Observable<boolean>;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private store$: Store,
  ) {
    this.saving$ = combineLatest([
      this.store$.select(FparTableSelectors.selectAddTableCommentLoading),
      this.store$.select(FparTableSelectors.selectUpdateTableCommentLoading),
      this.store$.select(FparTableSelectors.selectDeleteTableCommentLoading),
    ]).pipe(map((saving) => saving.some((s) => s)));

    this.actions$
      .pipe(
        ofType(
          FparTableActions.addTableCommentFailure,
          FparTableActions.updateTableCommentFailure,
          FparTableActions.deleteTableCommentFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public deleteComment(
    commentId: number,
    grantId: number,
    tableId: number,
  ): void {
    this.store$.dispatch(
      FparTableActions.deleteTableComment({
        commentId,
        grantId,
        tableId,
      }),
    );
  }

  public saveComment(
    payload: FparTableCommentPayload,
    grantId: number,
    tableId: number,
  ): void {
    const saveAction = !payload.id
      ? FparTableActions.addTableComment
      : FparTableActions.updateTableComment;

    this.store$.dispatch(
      saveAction({
        grantId,
        tableId,
        payload,
      }),
    );
  }

  public setEditingComment(value: boolean): void {
    this.editingComments.emit(value);
  }
}
