import { Pipe, PipeTransform } from '@angular/core';
import { FparTableIssue } from '@core/models';

@Pipe({
    name: 'issuesNotOnHold',
    standalone: false
})
export class IssuesNotOnHoldPipe implements PipeTransform {
  transform(issues: FparTableIssue[]): FparTableIssue[] {
    return issues.filter((issue) => !issue.onHold && !issue.resolved);
  }
}
