import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Resource } from '@app/shared/enums';
import { AppStoreState, ResourceAccessSelectors } from '@app/store';
import { ResourceAccessObject } from '@core/models';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[appSecurableResource]',
    standalone: false
})
export class SecurableResourceDirective
  implements OnChanges, OnDestroy, OnInit
{
  @Input() appSecurableResource: Resource[];

  public resourceAccess: ResourceAccessObject = {};

  private resourceAccess$: Observable<ResourceAccessObject>;
  private subscriptions = new Subscription();

  constructor(
    private store$: Store<AppStoreState.State>,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.resourceAccess$ = this.store$.select(
      ResourceAccessSelectors.selectResourceAccess,
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.resourceAccess$.subscribe((ra) => {
        this.resourceAccess = ra;
        this.setVisibility();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.appSecurableResource &&
      !changes.appSecurableResource.firstChange
    ) {
      this.setVisibility();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkResourceAccess(): boolean {
    return !this.appSecurableResource
      ? true
      : !this.resourceAccess
        ? false
        : this.appSecurableResource
            .map((r) => this.resourceAccess[r])
            .some((r) => r);
  }

  private setVisibility(): void {
    if (!this.checkResourceAccess()) {
      this.viewContainer.clear();
      return;
    }

    if (this.viewContainer.get(0) === null) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
