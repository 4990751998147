import { Pipe, PipeTransform } from '@angular/core';
import { Grant, GrantSubmission, User } from '@core/models';

@Pipe({
    name: 'fullGrantDisplay',
    standalone: false
})
export class FullGrantDisplayPipe implements PipeTransform {
  public transform(
    value: Grant | GrantSubmission | User,
    includeFparNumber = true,
  ): any {
    if (!value) {
      return '';
    }

    const granteeName = `${value.granteeName} - ${value.grState}`;

    return includeFparNumber
      ? `${value.grFparNumber}: ${granteeName}`
      : granteeName;
  }
}
