import { Pipe, PipeTransform } from '@angular/core';
import { FparIssueGroup } from '@core/models';

@Pipe({
    name: 'tablesWithIssues',
    standalone: false
})
export class TablesWithIssuesPipe implements PipeTransform {
  transform(tables: FparIssueGroup[]): FparIssueGroup[] {
    return tables.filter((table) =>
      table.list.some((issue) => !issue.onHold && !issue.resolved),
    );
  }
}
