import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IssueStatuses, NotificationTypes } from '@app/shared/enums';
import {
  FparTableActions,
  FparTableSelectors,
  NotificationActions,
} from '@app/store';
import {
  FparIssueComment,
  FparTableCellIssue,
  FparTableIssue,
  FparTableIssueCommentPayload,
} from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-fpar-issue',
    templateUrl: './fpar-issue.component.html',
    styleUrls: ['./fpar-issue.component.scss'],
    standalone: false
})
export class FparIssueComponent implements OnChanges, OnDestroy {
  @Input() canResolve = false;
  @Input() grant: { grantId: number; tableId: number };
  @Input() index: number;
  @Input() issue: FparTableIssue;
  @Input() readOnly = false;
  @Input() systemForcedStatusChange = false;
  @Input() reportingPeriodId: number | null = null;

  @Output() activateSubIssue = new EventEmitter<FparTableCellIssue>();
  @Output() editingComments = new EventEmitter<boolean>();
  @Output() hold = new EventEmitter<FparTableIssue>();
  @Output() resolve = new EventEmitter<FparTableIssue>();

  public hasComments = false;
  public issueStatus: IssueStatuses = IssueStatuses.None;
  public issueComments$: Observable<FparIssueComment[]>;
  public notificationTypes = NotificationTypes;
  public saving$: Observable<boolean>;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private store$: Store,
  ) {
    this.actions$
      .pipe(
        ofType(
          FparTableActions.addTableIssueCommentFailure,
          FparTableActions.updateTableIssueCommentFailure,
          FparTableActions.deleteTableIssueCommentFailure,
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(({ message }) =>
        this.store$.dispatch(
          NotificationActions.add({
            notificationType: NotificationTypes.DANGER,
            notificationText: message,
          }),
        ),
      );

    this.actions$
      .pipe(
        ofType(
          FparTableActions.holdTableIssueSuccess,
          FparTableActions.addTableIssueCommentSuccess,
          FparTableActions.updateTableIssueCommentSuccess,
          FparTableActions.deleteTableIssueCommentSuccess,
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.fetchTableIssueComments(
            this.grant.grantId,
            this.grant.tableId,
            this.issue.checkId,
            this.reportingPeriodId,
          );
        }),
      )
      .subscribe();

    this.saving$ = combineLatest([
      this.store$.select(
        FparTableSelectors.selectUpdateTableIssueCommentLoading,
      ),
      this.store$.select(FparTableSelectors.selectAddTableIssueCommentLoading),
      this.store$.select(
        FparTableSelectors.selectDeleteTableIssueCommentLoading,
      ),
    ]).pipe(
      map((loading) => loading.some((l) => l)),
      takeUntil(this.destroyed$),
    );

    this.issueComments$ = this.actions$.pipe(
      ofType(FparTableActions.getTableIssueCommentsSuccess),
      filter((action) => action.response.checkId === this.issue.checkId),
      map((action) => action.response.apiResponse.data),
      takeUntil(this.destroyed$),
      tap((comments) => {
        this.hasComments = comments.length > 0;

        this.issueStatus = this.issue.resolved
          ? IssueStatuses.Resolved
          : this.hasComments
            ? IssueStatuses.OnHold
            : IssueStatuses.Unresolved;
      }),
    );
  }

  public get IssueStatuses() {
    return IssueStatuses;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchTableIssueComments(
      this.grant.grantId,
      this.grant.tableId,
      this.issue.checkId,
      this.reportingPeriodId,
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public forcedSystemUpdateNoComments() {
    return (
      this.systemForcedStatusChange && !this.hasComments && this.canResolve
    );
  }

  public deleteComment(
    grantId: number,
    tableId: number,
    checkId: string,
    commentId: number,
  ): void {
    this.store$.dispatch(
      FparTableActions.deleteTableIssueComment({
        grantId,
        tableId,
        checkId,
        commentId,
      }),
    );
  }

  public onActivateSubIssue(subIssue: FparTableCellIssue): void {
    this.activateSubIssue.emit(subIssue);
  }

  public saveComment(
    grantId: number,
    tableId: number,
    checkId: string,
    payload: FparTableIssueCommentPayload,
  ): void {
    const commentId = payload.id;
    const action =
      payload.parentCommentId || !commentId
        ? FparTableActions.addTableIssueComment({
            grantId,
            tableId,
            checkId,
            payload,
          })
        : FparTableActions.updateTableIssueComment({
            grantId,
            tableId,
            checkId,
            commentId,
            payload,
          });
    this.store$.dispatch(action);
  }

  public setEditingComment(value: boolean): void {
    this.editingComments.emit(value);
  }

  public setOnHold(): void {
    this.hold.emit(this.issue);
  }

  public setResolved(): void {
    this.resolve.emit(this.issue);
  }

  private fetchTableIssueComments(
    grantId: number,
    tableId: number,
    checkId: string,
    reportingPeriodId: number | null,
  ): void {
    this.store$.dispatch(
      FparTableActions.getTableIssueComments({
        grantId,
        tableId,
        checkId,
        reportingPeriodId,
      }),
    );
  }
}
