import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camel-case-to-spaces',
    standalone: false
})
export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(text: string): string {
    const spacesAdded = text.replace(/[(A-Z)]/g, ' $&');
    const lowered = spacesAdded.toLowerCase();
    return lowered[0].toUpperCase() + lowered.slice(1);
  }
}
