import { Component } from '@angular/core';

@Component({
    selector: 'app-submission-closed',
    templateUrl: './submission-closed.component.html',
    styleUrls: ['./submission-closed.component.scss'],
    standalone: false
})
export class SubmissionClosedComponent {
  public today = new Date();
}
