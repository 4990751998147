import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FparTableCellIssue } from '@core/models';
import { MdePopoverTrigger } from '@muntazir86/material-extended';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-view-issue-link',
    templateUrl: './view-issue-link.component.html',
    styleUrls: ['./view-issue-link.component.scss'],
    standalone: false
})
export class ViewIssueLinkComponent {
  @Input() currentSubIssueIndex: number;
  @Input() subIssue: FparTableCellIssue;
  @Input() subIssueIndex: number;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() popoverClose: EventEmitter<any> = new EventEmitter();
  @Output() popoverOpen: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();
  /* eslint-enable @typescript-eslint/no-explicit-any */

  @ViewChild(MdePopoverTrigger) popoverTrigger!: MdePopoverTrigger;

  constructor() {}

  public get isOpen(): boolean {
    return this.currentSubIssueIndex === this.subIssueIndex;
  }

  public closePopover(): void {
    this.popoverClose.emit(-1);
  }

  public nextSubIssue(): void {
    this.next.emit();
  }

  public openPopover(): void {
    this.popoverOpen.emit(this.subIssueIndex);
  }

  public previousSubIssue(): void {
    this.previous.emit();
  }

  public togglePopover($event): void {
    $event.preventDefault();
    if (this.popoverTrigger?.popoverOpen) {
      this.closePopover();
    } else {
      this.openPopover();
    }
  }
}
