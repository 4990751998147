import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './idle-timeout.component.html',
    styleUrls: ['./idle-timeout.component.scss'],
    standalone: false
})
export class IdleTimeoutDialogComponent implements OnDestroy {
  public timeRemaining;
  private timeOut = 60;
  private destroyed$ = new Subject<boolean>();
  private worker: Worker;

  constructor(
    private dialog: MatDialogRef<IdleTimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    this.timeRemaining = this.timeOut;

    // instantiate web worker to handle background timer
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('./idle-timeout.worker', import.meta.url),
      );
      this.worker.onmessage = ({ data }) => {
        if (data === 1) {
          this.close();
        }
        this.timeRemaining = data;
      };
      this.worker.postMessage(this.timeOut);
    }
  }

  ngOnDestroy(): void {
    this.worker.terminate();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public close(): void {
    this.worker.terminate();
    this.dialog.close(false);
  }

  public submit(): void {
    this.worker.terminate();
    this.dialog.close(true);
  }
}
