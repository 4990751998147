import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
    standalone: false
})
export class CapitalizePipe implements PipeTransform {
  transform(text: string): string {
    return text[0].toUpperCase() + text.substring(1);
  }
}
