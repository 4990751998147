import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationTypes, Resource } from '@app/shared/enums';
import {
  AppStoreState,
  GrantActions,
  GrantSelectors,
  NotificationActions,
  ResourceAccessSelectors,
  TableArchiveSelectors,
} from '@app/store';
import {
  ConfirmDialog,
  FparSubmissionComment,
  ResourceAccessObject,
} from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialogs';

@Component({
    selector: 'app-submission-comment',
    templateUrl: './submission-comment.component.html',
    styleUrls: ['./submission-comment.component.scss'],
    standalone: false
})
export class SubmissionCommentComponent implements OnDestroy, OnInit {
  @Input() grantId: number;
  @Input() readOnly = false;
  @Input() viewOnly = false;
  @Input() tableArchive = false;

  public canEditComments = false;
  public canSaveComments = false;
  public comment: string;
  public commentForm: UntypedFormGroup;
  public reportingPeriodId: number;
  public submissionComment: FparSubmissionComment;
  public submissionCommentLoading$ = this.store$.select(
    GrantSelectors.selectSubmissionCommentLoading,
  );

  private destroyed$ = new Subject<boolean>();
  private resourceAccess$: Observable<ResourceAccessObject>;
  private selectedReportingPeriodId$ = this.store$.select(
    TableArchiveSelectors.selectSelectedReportingPeriodId,
  );
  private submissionComment$ = this.store$.select(
    GrantSelectors.selectSubmissionComment,
  );

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private store$: Store<AppStoreState.State>,
  ) {
    this.resourceAccess$ = this.store$.select(
      ResourceAccessSelectors.selectResourceAccess,
    );

    this.actions$
      .pipe(
        ofType(GrantActions.updateSubmissionCommentSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: 'Your comment has been saved.',
            }),
          );
          this.fetchData();
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(GrantActions.deleteSubmissionCommentSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.commentForm.reset();
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: 'Your comment has been deleted.',
            }),
          );
          this.fetchData();
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          GrantActions.updateSubmissionCommentFailure,
          GrantActions.deleteSubmissionCommentFailure,
          GrantActions.getSubmissionCommentFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();
  }

  public get placeholder(): string {
    return this.viewOnly
      ? 'No comments provided.'
      : 'Enter optional comments here...';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    if (this.tableArchive) {
      this.selectedReportingPeriodId$
        .pipe(
          filter((reportingPeriodId) => !!reportingPeriodId),
          takeUntil(this.destroyed$),
        )
        .subscribe((reportingPeriodId) => {
          this.reportingPeriodId = reportingPeriodId;
          this.fetchData();
        });
    }

    this.commentForm = this.formBuilder.group({
      comment: '',
    });

    this.submissionComment$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((submissionComment) => {
        this.submissionComment = submissionComment;
        const comment = submissionComment ? submissionComment.comment : null;
        this.commentForm.setValue({ comment });
      });

    this.resourceAccess$
      .pipe(
        filter((resourceAccess) => !!resourceAccess),
        takeUntil(this.destroyed$),
      )
      .subscribe((resourceAccess) => {
        this.canEditComments =
          !!resourceAccess[Resource.EditSubmissionComment] && !this.readOnly;
        this.canSaveComments =
          !!resourceAccess[Resource.CreateNewSubmissionComment];
      });
  }

  public canDelete(): boolean {
    return !!this.submissionComment?.commentId;
  }

  public canSave(): boolean {
    return this.commentForm.dirty && this.canSaveComments;
  }

  public deleteComment(): void {
    const data: ConfirmDialog = {
      confirmColor: 'warn',
      message: 'Are you sure you want to delete this comment?',
      title: 'Confirm Delete',
    };

    this.dialog
      .open(ConfirmDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.store$.dispatch(
            GrantActions.deleteSubmissionComment({
              grantId: this.grantId,
              commentId: this.submissionComment.commentId,
            }),
          );
        }
      });
  }

  public saveComment(): void {
    this.store$.dispatch(
      GrantActions.updateSubmissionComment({
        grantId: this.grantId,
        comment: this.commentForm.value.comment,
      }),
    );
  }

  private fetchData(): void {
    this.store$.dispatch(
      GrantActions.getSubmissionComment({
        grantId: this.grantId,
        reportingPeriodId: this.reportingPeriodId,
      }),
    );
  }
}
