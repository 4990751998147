import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationTypes, PanelWidth } from '@app/shared/enums';
import { CapitalizePipe } from '@app/shared/pipes';
import { FparTableActions, NotificationActions } from '@app/store';
import {
  ConfirmDialog,
  FparIssueGroup,
  FparTableCellIssue,
  FparTableIssue,
} from '@core/models';
import { PanelService } from '@core/services';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialogs';
import { IssueOnHoldPanelComponent } from './panels';

@Component({
    selector: 'app-fpar-table-issues',
    templateUrl: './fpar-table-issues.component.html',
    styleUrls: ['./fpar-table-issues.component.scss'],
    standalone: false
})
export class FparTableIssuesComponent implements OnDestroy {
  @Input() canResolve = false;
  @Input() issueGroups: FparIssueGroup[] = [];
  @Input() grantId: number;
  @Input() readOnly = false;
  @Input() tableId: number;
  @Input() systemForcedStatusChange = false;
  @Input() reportingPeriodId: number | null = null;

  @Output() activateSubIssue = new EventEmitter<FparTableCellIssue>();
  @Output() editingComments = new EventEmitter<boolean>();

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private capitalizePipe: CapitalizePipe,
    private dialog: MatDialog,
    private panelService: PanelService,
    private store$: Store,
  ) {
    this.actions$
      .pipe(
        ofType(
          FparTableActions.holdTableIssueFailure,
          FparTableActions.updateTableIssueFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(FparTableActions.holdTableIssueSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: `Issue is "Flagged"`,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(FparTableActions.updateTableIssueSuccess),
        takeUntil(this.destroyed$),
        tap(({ payload }) => {
          const name = payload.resolved ? 'resolved' : 'unresolved';

          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: `Issue marked "${name}".`,
            }),
          );
          this.store$.dispatch(
            FparTableActions.getTableIssues({
              grantId: this.grantId,
              tableId: this.tableId,
            }),
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public markOnHold(
    grantId: number,
    tableId: number,
    issue: FparTableIssue,
  ): void {
    const checkId = issue.checkId;
    this.panelService
      .open<boolean, any>(
        IssueOnHoldPanelComponent,
        { grantId, tableId, checkId, issue },
        {
          maxWidth: PanelWidth.Medium,
        },
      )
      .afterClosed()
      .subscribe();
  }

  public setResolution(grantId: number, issue: FparTableIssue): void {
    const { issueId, resolved } = issue;
    const name = resolved ? 'unresolve' : 'resolve';
    const title = this.capitalizePipe.transform(name);

    const confirmDialogConfig: ConfirmDialog = {
      confirmColor: 'warn',
      message: `Are you sure you want to mark this issue as ${name}d?`,
      title: `${title} issue`,
    };

    this.dialog
      .open(ConfirmDialogComponent, {
        data: confirmDialogConfig,
      })
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe(() => {
        this.store$.dispatch(
          FparTableActions.updateTableIssue({
            grantId,
            issueId,
            payload: { resolved: !resolved },
          }),
        );
      });
  }

  public onActivateSubIssue(subIssue: FparTableCellIssue): void {
    this.activateSubIssue.emit(subIssue);
  }

  public setEditingComments(value: boolean): void {
    this.editingComments.emit(value);
  }
}
