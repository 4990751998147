import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AppStoreState, GrantSelectors } from '@app/store';
import { FileUploadResult, FileValidationIssue } from '@core/models';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-validation-warnings',
    templateUrl: './validation-warnings.component.html',
    styleUrls: ['./validation-warnings.component.scss'],
    standalone: false
})
export class ValidationWarningsComponent implements OnDestroy, OnInit {
  @Input() public fileUploadResult$: Observable<FileUploadResult>;
  @Input() public isPanel: boolean;
  @Input() public validationIsOpen: boolean;
  @Output() public exportButtonClicked = new EventEmitter();
  @Output() public closeButtonClicked = new EventEmitter();

  public fileUploadResultsLoading$ = this.store$.select(
    GrantSelectors.selectFileUploadResultsLoading,
  );
  public errors: boolean;
  public loaded = false;
  public warnings: FileValidationIssue[];
  public warningCount: number;
  public warningCountMessage: string;

  private destroyed$ = new Subject<boolean>();

  constructor(private store$: Store<AppStoreState.State>) {}

  public get exportButtonClass() {
    return this.isPanel ? 'primary' : 'white';
  }

  ngOnInit(): void {
    this.fileUploadResult$
      .pipe(
        filter((result) => !!result),
        takeUntil(this.destroyed$),
      )
      .subscribe((result) => {
        this.errors = !!result.errors.length;
        this.warnings = result.warnings;
        this.warningCount = result.warnings.reduce(
          (sum, warningType) => sum + warningType.messages.length,
          0,
        );
        const message = `${this.warningCount} invalid value warning`;
        this.warningCountMessage =
          this.warningCount === 1 ? message : `${message}s`;
        this.loaded = true;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public close() {
    this.closeButtonClicked.emit();
  }

  public exportWarnings() {
    this.exportButtonClicked.emit();
  }
}
