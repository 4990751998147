import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ContentContainerTitleComponent } from './content-container-subs';

@Component({
    selector: 'app-content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss'],
    standalone: false
})
export class ContentContainerComponent implements OnInit {
  @Input() loading = false;
  @Input() progress = false;

  @ViewChild('contentContainerHeader') public containerHeader: ElementRef;

  @ContentChild(ContentContainerTitleComponent) contentContainerTitle: any;

  constructor() {}

  ngOnInit(): void {}
}
