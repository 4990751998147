import { Component, Input } from '@angular/core';
import { QRCodeComponent } from 'angularx-qrcode';

@Component({
  imports: [QRCodeComponent],
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
  standalone: true,
})
export class QrCodeComponent {
  @Input() qrcode = '';
  @Input() elementType: string;
  @Input() cssClass: string;
  @Input() alt: string;
  @Input() version: string;
  @Input() errorCorrectionLevel: string;
  @Input() margin: number;
  @Input() scale: number;
  @Input() width: number;
  @Input() colorDark: string;
  @Input() colorLight: string;
  constructor() {}
}
